.items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  margin: 1rem 0;
}

.itemWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  height: 200px;
}

.name {
  font-size: 15pt;
  font-weight: 700;
}

.price {
  font-size: 12pt;
  font-weight: 500;
  color: #24c486;
}

.buttons {
  display: flex;
  justify-content: center;
}

.button {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: #24c486;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 70px;
}

.sell {
  background: linear-gradient(180deg, #f53b82, #f53b57);
}

.button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.amount {
  margin: 0 1rem;
  align-self: center;
}
