.counter {
    display:flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg,#2ecc71,#1abc9c);
    color: #fff;
    font-size: 25pt;
    font-weight: 700;
    height: 50px;
    padding: 1rem 0;
}