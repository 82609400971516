body {
  background-color: #f1f2f6;
}
.App {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 100vh; 
}